html, body {
    max-width: 100%;
    overflow-x: hidden;
}
.funnyJoke{
    text-decoration: line-through;
}
.section1{
     color: black;
}
.section2{
    color: aliceblue;
}
h1{
    margin-left: 0px;
    font-family: Avenir, Nunito, Calibri;
}
h2{
    font-family: Avenir, Nunito, Calibri;
}
h3{
    font-family: Avenir, Nunito, Calibri;
}
h4{
    font-family: Avenir, Nunito, Calibri;
}
h5{
    font-family: Avenir, Nunito, Calibri;
}
h6{
    font-family: Avenir, Nunito, Calibri;
}
p{
    margin-left: 0px;
    font-family: Avenir, Nunito, Calibri;
}
button{
    font-family: Avenir, Nunito, Calibri;
}
.capLeftSpace {
     width: 160px;
     height: 100px;
     background-color: rgba(255, 255, 255, 0.55);
     display: flex;
     justify-content: flex-end;
     border-radius: 50px;

     cursor: pointer;
 }
.capLeftSpace[data-isOn="true"] {
    justify-content: flex-start;
}
.capLeft {
    width: 100px;
    height: 85px;
    background-color: mediumvioletred;
    border-radius: 50px 0px 0px 50px;
}


.capRightSpace {
    width: 160px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.55);
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;

    cursor: pointer;
}
.capRightSpace[data-isOn="true"] {
    justify-content: flex-end;
}
.capRight {
    width: 100px;
    height: 85px;
    background-color: powderblue;
    border-radius: 0px 50px 50px 0px;
}
.pellet{
    width: 0px;
    height: 85px;
    visibility: hidden;
    display: flex;
    justify-content: center;
}
.pellet[data-isOn="true"]{
    width: 200px;
    height: 85px;
    visibility: visible;
    justify-content: space-around;
}
.pelletButton{
    width: 85px;
    height: 85px;

    background-color: #000000;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    border-radius: 50px 50px 50px 50px;
}
.mythicalWhiteSpace{
    height: 10%;
}
.mythicalWhiteSpace[data-isOn="true"]{
    height: 10px;
}
.backButton{
    background-color: #ffffff;
    color: black;
    font-size: 20px;
    border: none;

    margin-top:5px;
    margin-bottom:5px;
    text-align:center;
}
.tutorial{
    visibility: visible;
}
.tutorial[data-isOn="true"]{
    visibility: hidden;
}