.articleText{
      text-align:left;
      max-width: 900px;


      margin: 15px auto;
  }
.articleTextTitle{
    text-align:center;
    max-width: 900px;


    margin: 15px auto;
}
.articleImageCenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}